<template>
  <div class="SpreadMgrForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block>

          <el-form-item label="推广标签" :rules="[{ required: true, message: '请选择推广标签', trigger: 'change' }]" prop="showType">
            <div v-show="showTypeText" v-text="showTypeText" />
            <v-select v-show="!showTypeText" v-model="form.showType" :options="showTypeOps" />
          </el-form-item>

          <el-form-item label="本次适配项目" :rules="[{ required: true, message: '请选择本次适配项目', trigger: 'change' }]" prop="communityList">
            <div v-if="communityListText" v-text="communityListText" />
            <v-button v-else text="点击选择" @click="selectCommunity" />
          </el-form-item>

          <div v-if="showTable">
            <el-form-item label="应用推荐概况">
              <div class="operation">
                <div v-if="pageType === 0" v-show="false" class="operation-item">
                  项目名称：
                  <v-select ref="communityName" class="select-communityName" v-model="communityId" async-options placeholder="请输入项目名称" :options="communityOps" filterable clearable :width="150" @change="communityChange" />
                </div>
                <div class="operation-item" v-show="false">
                  应用类型：
                  <v-select v-model="subjectType" :options="subjectTypeOps"  :width="150" />
                </div>
                <div class="operation-item">
                  应用名称：
                  <v-select v-model="subId" async-options value-type="string,number" placeholder="请输入应用名称" :options="subjectOps"  filterable clearable :width="150" />
                </div>
                <v-button type="success" text="搜索" @click="search" />
              </div>
              <el-transfe v-model="selectedHeaders" :data="tableHeaders">

                
              </el-transfe>
              <vxe-table
                ref="vxeTable"
                :data="tableData"
                max-height="345"
                show-overflow
                border
              >
                <template v-for="(item, index) in tableHeaders">
                                <!-- :filters="index === 0 ? communityNameFilterList : undefined"
                    :filter-method="index === 0 ? filterNameMethod : undefined" -->
                  <vxe-table-column
                    :key="index"
                    :field="item.id"
                    :title="item.name"
                    :fixed="index === 0 ? 'left' : undefined"
      
                    header-class-name="table-column-header"
                    :class-name="index === 0 ? '' : 'table-column'"
                    min-width="120"
                    align="center"
                  >
                    <template slot="header" slot-scope="scope">
                      <!-- 使用v-show而不是v-if，是为了避免使用表格筛选功能时报错 -->
                      <v-checkbox v-show="item.id != 'communityName'" v-model="item.checked" :indeterminate="item.isIndeterminate" :disabled="item.disabled" :label="item.name" @change="handleCheckAllChange(scope, item)" />
                    </template>
                    <template slot-scope="scope">
                      <span v-if="index === 0" v-text="scope.row[item.id]" />
                      <span v-else class="vxe-cell-status" :style="{ 'cursor': scope.row[item.id] === undefined ? 'not-allowed' : 'pointer', 'background-color': getColor(scope.row[item.id]) }" @click="cellStatusClick(scope, item, index)" />
                    </template>
                  </vxe-table-column>
                </template>
              </vxe-table>
            </el-form-item>

            <el-form-item label="推广预览">
              <div v-if="pageType === 0" class="operation">
                <div class="operation-item">
                  单应用全项目移动：
                  <v-select v-model="moveSubject" placeholder="请输入应用名称" :options="moveSubjectOps" filterable clearable :width="150" />
                </div>
                <div class="operation-item">
                  排序：
                  <v-input-number placeholder="请输入排序" v-model="moveSort" controls controlsPosition="right" :min="1" :max="99" />
                </div>
                <v-button type="success" text="确认排序" @click="sort" />
              </div>
              <div
                class="subject-list"
                v-for="(item, index) in previewTableData"
                :key="index"
              >
                <div>{{ item.data.communityName }}：</div>
                <sortable-list
                  class="columns"
                  v-model="item.columns"
                  axis="x"
                  lockAxis="x"
                  lockOffset="100%"
                  lockToContainerEdges
                  use-drag-handle
                  @sort-start="sortStart"
                  @input="sortEnd"
                >
                  <transition-group class="columns-list" name="list-complete">
                    <sortable-item
                      class="columns-item"
                      :class="moving ? '' : 'list-complete-item'"
                      v-for="(columnItem, columnIndex) in item.columns"
                      :key="columnItem.id"
                      :index="columnIndex"
                      :item="columnItem"
                      :headers="tableHeaders"
                      :rowIndex="index"
                      :row="item.data"
                      :method="cellStatusClick"
                    />
                  </transition-group>
                </sortable-list>
              </div>
            </el-form-item>
          </div>

        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      title="项目列表"
      :isShow.sync="isShow"
      :searchUrl="getCommunityListURL"
      :headers="communityTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="form.communityList"
      :handleData="handleData"
      @callback="callback">
        <template #searchSlot>
          <v-input v-model="searchParams.communityName" label="项目名称" />
          <!-- <v-select v-model="searchParams.communityStatus" :options="communityStatusOps" label="项目状态"/> -->
          <v-select v-model="searchParams.communityStage" :options="communityStageOps" label="项目阶段"/>
          <v-select2 v-model="searchParams.regionId" label="所属公司" placeholder="查询所属公司" v-bind="tenantParams"/>
        </template>
    </multi-select>
    <el-dialog
      width="500px"
      title="应用推广配置错误"
      :visible.sync="dialogVisible"
      @closed="closeDialog"
    >
      <div class="dialog-content">
        <p>您所配置的</p>
        <div class="info-list">
          <span class="info-item">{{ dialogInfo }}</span>
        </div>
        <p>应用已超出推荐列表上限，无法再添加</p>
        <p>请知悉</p>
      </div>
      <v-button text="知道了" @click="dialogVisible = false" />
    </el-dialog>
  </div>
</template>

<script>
import { getSubjectPromotionMatrixURL, saveSubjectPromotionMatrixURL, getCommunityStageOpsURL, getCommunityListURL } from './api'
import { setShowTypeOps, showTypeMap, setCommunityStatusOps, communityStatusMap, setSubjectTypeOps } from './map'
import { vCheckbox } from 'components/control'
import { Col2Detail, Col2Block, MultiSelect } from 'components/bussiness'
import { Tag } from 'element-ui'
import { ContainerMixin, ElementMixin, HandleDirective } from 'vue-slicksort'

import Vue from 'vue'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'
import { tenantParams } from '@/common/select2Params'

Vue.use(VXETable)

const SortableList = {
  mixins: [ContainerMixin],
  template: `
    <div>
      <slot />
    </div>
  `
}

const SortableItem = {
  components: {
    elTag: Tag,
  },
  mixins: [ElementMixin],
  props: ['item', 'headers', 'rowIndex', 'row', 'method'],
  directives: { handle: HandleDirective },
  template: `
    <div>
      <el-tag :type="item.operable === 1 ? undefined : 'info'" :closable="item.operable === 1 ? true : false" @close="handleClose(item)">
        <span v-if="item.operable === 1" class="tag-text tag-text-operable" v-handle>{{ item.name }}</span>
        <span v-else class="tag-text">{{ item.name }}</span>
      </el-tag>
    </div>
  `,
  methods: {
    handleClose (item) {
      const columnIndex = this.headers.findIndex(header => {
        return header.id === item.id
      })
      const columnItem = this.headers[columnIndex]
      this.method({
        rowIndex: this.rowIndex,
        row: this.row
      }, columnItem, columnIndex)
    }
  }
}

const SUBJECT_MAX_NUMBER = {
  '0': 9,
  '1': 20,
  '2': 20,
  '3': 10
}
const COLOR = {
  '0': '#FFFFFF', // 未选
  '1': '#74C367', // 已选
  'undefined': '#d2d2d2' // 不能选
}

export default {
  name: 'SpreadMgrForm',
  components: {
    vCheckbox,
    Col2Detail,
    Col2Block,
    MultiSelect,
    SortableList,
    SortableItem,

  },
  data () {
    return {
      pageType: 0,
      moveSort: undefined,
      moveSubject: null,
      moveSubjectOps: [],
      showTypeOps: setShowTypeOps(2),
      showTable: false,
      showHeader: true,
      form: {
        showType: undefined,
        communityList: []
      },
      tableHeaders: [],
      selectedHeaders:[],
      tableData: [],
      moving: false,
      previewTableData: [],
      isShow: false,
      getCommunityListURL,
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        }, {
          prop: 'communityStatusText',
          label: '项目状态'
        }, {
          prop: 'communityStage',
          label: '项目阶段'
        // }, {
        //   prop: 'countryName',
        //   label: '所在区'
        // }, {
        //   prop: 'cityName',
        //   label: '所在市'
        // }, {
        //   prop: 'provinceName',
        //   label: '所在省'
        }, {
          prop: 'regionName',
          label: '所属公司'
        }
      ],
      // 应用推荐概况 搜索项配置
      communityId: null,
      communityOps: [],
      subjectType: undefined,
      subjectTypeOps: setSubjectTypeOps(1),
      subId: null,
      subjectOps: [],
      subjectAllOps: {
        '1': [],
        '2': [],
        '3': []
      },
      communityNameFilterList: [
        { label: '', value: undefined, checked: false }
      ],
      // multi-select 搜索项配置
      communityStatusOps: setCommunityStatusOps(1),
      communityStageOps: [{
        text: '全部',
        value: undefined
      }],
      tenantParams,
      searchParams: {
        communityName: '',
        communityStatus: 0,
        communityStage: undefined,
        regionId: undefined,
        isNormal: 1
      },
      responseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      // dialog 配置
      dialogInfo: '',
      dialogVisible: false,
      submitConfig: {
        submitUrl: saveSubjectPromotionMatrixURL,
        submitMethod: 'POST'
      }
    }
  },
  mounted () {
    const { communityId, communityName, promotionType } = this.$route.query
    if (communityId !== undefined && communityName !== undefined && promotionType !== undefined) {
      this.$setBreadcrumb('编辑')
      this.pageType = 1
      this.form.showType = promotionType
      this.form.communityList = [{
        id: communityId,
        text: communityName
      }]
    } else {
      this.$setBreadcrumb('推广配置')
      this.getCommunityStageOps()
    }
  },
  computed: {
    canGetSubjectPromotionMatrix () {
      return this.form.showType !== undefined && !!(this.form.communityList && this.form.communityList.length)
    },
    subjectMaxNumber () {
      const showType = this.form.showType
      return showType !== undefined ? SUBJECT_MAX_NUMBER[String(showType)] : 0
    },
    showTypeText () {
      const showType = this.form.showType
      return showType !== undefined ? showTypeMap[showType] : ''
    },
    communityListText () {
      const list = []
      this.form.communityList.forEach(item => {
        list.push(item.text)
      })
      return list.join('、')
    },
    subVisible () {
      return this.subjectType !== undefined
    }
  },
  watch: {
    canGetSubjectPromotionMatrix () {
      const communityIds = []
      this.form.communityList.forEach(item => {
        communityIds.push(item.id)
      })
      const params = {
        promotionType: this.form.showType,
        communityIds
      }
      this.getSubjectPromotionMatrix(params)
    },
    previewTableData: {
      handler (newVal, oldVal) {
        const ops = []
        newVal.forEach(item => {
          item.columns.forEach(column => {
            const index = ops.find(op => {
              return op.value === column.id
            })
            if (index === undefined) {
              ops.push({
                text: column.name,
                value: column.id
              })
            }
          })
        })
        // 若选项中无当前选项，则清空当前选项
        if (this.moveSubject !== null) {
          const index = ops.findIndex(op => {
            return this.moveSubject === op.value
          })
          if (index === -1) {
            this.moveSubject = null
          }
        }
        this.moveSubjectOps = ops
      },
      deep: true
    }
  },
  methods: {
    async getSubjectPromotionMatrix (params) {
      const loading = this.$loading({
        lock: true,
        target: '.form-panel-container .content'
      })
      this.$refs.formPanel.setSubmitDisabled(true)
      const { status, data } = await this.$axios.post(getSubjectPromotionMatrixURL, params)
      if (status === 100) {
        if (Array.isArray(data.rows) && data.rows.length) {
          const tableData = []
          data.rows.forEach((item, index) => {
            const obj = {
              communityId: item.communityId,
              communityName: item.communityName,
              columns: []
            }
            if (Array.isArray(item.subjects) && item.subjects.length) {
              item.subjects.forEach(subject => {
                // 平台账号只能操作平台创建的应用(包含已授权给租户的应用)
                obj[subject.subCode] = subject.operable === 1 ? subject.status : undefined
                if (subject.status === 1) {
                  obj['columns'].push({
                    id: String(subject.subCode),
                    name: subject.subName,
                    operable: subject.operable
                  })
                }
              })
            }
            tableData.push(obj)
          })
          this.tableData = tableData
        }
        if (Array.isArray(data.headers) && data.headers.length) {
          let tableHeaders = [
            {
              id: 'communityName',
              name: '',
              currentNumber: 0,
              optionalNumber: 0,
              checked: false,
              isIndeterminate: false,
              disabled: false
            }
          ]
          data.headers.forEach((item, index) => {
            let currentNumber = 0
            let optionalNumber = 0
            this.tableData.forEach(row => {
              if (row[item.subCode] === 0 || row[item.subCode] === 1) {
                optionalNumber++
                if (row[item.subCode] === 1) {
                  currentNumber++
                }
              }
            })
            let checked = false
            let isIndeterminate = false
            let disabled = false
            if (optionalNumber === 0) {
              disabled = true
            } else if (currentNumber > 0 && currentNumber < optionalNumber) {
              isIndeterminate = true
            } else if (currentNumber === optionalNumber) {
              checked = true
            }
            tableHeaders.push({
              id: String(item.subCode),
              name: item.subName,
              currentNumber,
              optionalNumber,
              checked,
              isIndeterminate,
              disabled
            })
            const dataLevel = String(item.dataLevel)
            if (Object.keys(this.subjectAllOps).includes(dataLevel)) {
              this.subjectAllOps[item.dataLevel].push({
                text: item.subName,
                value: String(item.subCode)
              })
            }
            let canSelect = []
            let cannotSelect = []
            tableHeaders.shift()
            tableHeaders.forEach(headerItem=>{
              console.log(headerItem)
              if(data.rows.some(item=>item.subjects.findIndex(i=>i.subCode ==  headerItem.id) >= 0)){
                canSelect.push(headerItem)
              }else{
                cannotSelect.push(headerItem)
              }
            })
            console.log(canSelect,cannotSelect)
            
            tableHeaders = [
              {
                id: 'communityName',
                name: '',
                currentNumber: 0,
                optionalNumber: 0,
                checked: false,
                isIndeterminate: false,
                disabled: false
              }
            ].concat(canSelect).concat(cannotSelect)
          })
          this.tableHeaders = tableHeaders
        }
        this.tableData.forEach(data => {
          const columns = []
          data.columns.forEach(column => {
            columns.push({
              id: column.id,
              name: column.name,
              operable: column.operable
            })
          })
          this.previewTableData.push({
            columns,
            data
          })
        })
        this.showTable = true
        this.subjectOps = this.subjectAllOps[1]
      }
      loading.close()
      this.$refs.formPanel.setSubmitDisabled(false)
    },
    async getCommunityStageOps () {
      const { status, data } = await this.$axios.get(getCommunityStageOpsURL)
      if (status === '100') {
        if (Array.isArray(data) && data.length) {
          data.forEach(item => {
            this.communityStageOps.push({
              text: item.value,
              value: item.id
            })
          })
        }
      }
    },
    handleData (data) {
      data.communityStatusText = communityStatusMap[data.communityStatus]
    },
    callback (list, ids) {
      this.$refs.formPanel.validateField('communityList')
      if (Array.isArray(list) && list.length) {
        const communityOps = []
        list.forEach(item => {
          communityOps.push({
            text: item.text,
            value: item.id
          })
        })
        this.communityOps = communityOps
      }
    },
    selectCommunity () {
      this.isShow = true
    },
    getColor (key) {
      return COLOR[String(key)]
    },
    openDialog (list) {
      let dialogInfo = ' '
      if (Array.isArray(list) && list.length) {
        list.forEach(item => {
          dialogInfo += `"${item.communityName}——${item.subjectName}" `
        })
      }
      this.dialogInfo = dialogInfo
      this.dialogVisible = true
    },
    cellStatusClick (scope, columnItem, columnIndex) {
      const rowIndex = scope.rowIndex
      const key = columnItem.id
      const value = scope.row[key]
      if (value === undefined) {
        return
      } else if (value === 0) { // 勾选
        const currentSubjectNumber = this.previewTableData[rowIndex].columns.length
        if (currentSubjectNumber >= this.subjectMaxNumber) {
          const infoList = [{
            communityName: scope.row.communityName,
            subjectName: columnItem.name
          }]
          this.openDialog(infoList)
          return
        }
        scope.row[key] = 1
        columnItem['currentNumber']++
        this.previewTableData[rowIndex].columns.push({
          name: columnItem.name,
          id: columnItem.id,
          operable: 1
        })
      } else if (value === 1) { // 取消勾选
        scope.row[key] = 0
        columnItem['currentNumber']--
        const targetColumnIndex = this.previewTableData[rowIndex].columns.findIndex(column => {
          return column.id === key
        })
        if (targetColumnIndex !== -1) {
          this.previewTableData[rowIndex].columns.splice(targetColumnIndex, 1)
        }
      }
      this.$set(columnItem, 'checked', !!(columnItem.currentNumber === columnItem.optionalNumber))
      this.$set(columnItem, 'isIndeterminate', !!(columnItem.currentNumber > 0 & columnItem.currentNumber < columnItem.optionalNumber))
    },
    handleCheckAllChange (scope, columnItem) {
      const columnIndex = scope.$columnIndex
      const key = columnItem.id
      const value = columnItem.checked ? 0 : 1
      if (value === 1) { // 勾选
        const infoList = []
        this.tableData.forEach((row, rowIndex) => {
          if (row[key] !== undefined && row[key] !== value) {
            const currentSubjectNumber = this.previewTableData[rowIndex].columns.length
            if (currentSubjectNumber >= this.subjectMaxNumber) {
              infoList.push({
                communityName: row.communityName,
                subjectName: columnItem.name
              })
            } else {
              this.cellStatusClick({
                rowIndex,
                row
              }, columnItem, columnIndex)
            }
          }
        })
        if (infoList.length > 0) {
          this.openDialog(infoList)
          this.$nextTick(() => {
            this.$set(columnItem, 'checked', !!(columnItem.currentNumber === columnItem.optionalNumber))
            this.$set(columnItem, 'isIndeterminate', !!(columnItem.currentNumber > 0 & columnItem.currentNumber < columnItem.optionalNumber))
          })
        }
      } else if (value === 0) { // 取消勾选
        this.tableData.forEach((row, rowIndex) => {
          if (row[key] !== undefined && row[key] !== value) {
            this.cellStatusClick({
              rowIndex,
              row
            }, columnItem, columnIndex)
          }
        })
      }
    },
    communityChange () {
      this.$refs.communityName.blur()
    },
    subjectTypeChange (val) {
      if (this.subId !== null) {
        this.subId = null
      }
      if (val === undefined) {
        this.subjectOps = []
      } else {
        this.subjectOps = this.subjectAllOps[val]
      }
    },
    filterNameMethod ({ value, row, column }) {
      return row.communityId === value
    },
    async search () {
      // if (!this.subId && this.subjectType !== undefined) {
      //   this.$message.info('请先选择应用名称')
      //   return
      // }
      const vxeTable = this.$refs.vxeTable
      const communityId = this.communityId
      const subId = this.subId
      // const subjectType = this.subjectType
      // row
      const options = await vxeTable.filter('communityName')
      // if (Array.isArray(options) && options.length) {
      //   options[0].value = communityId
      //   if (communityId === null) {
      //     options[0].checked = false
      //   } else {
      //     options[0].checked = true
      //   }
      // }
      // column
      await vxeTable.resetColumn()
      const columns = vxeTable.getColumns()
      if (!!subId) { 
        if (Array.isArray(columns) && columns.length) {
          columns.forEach(columnItem => {
            if (columnItem.property !== 'communityName' && columnItem.property !== subId) {
              vxeTable.hideColumn(columnItem)
            }
          })
        }
      }
      await vxeTable.refreshColumn()
      await vxeTable.updateData()
      vxeTable.refreshScroll()
    },
    sort () {
      if (this.moveSubject === null) {
        this.$message.info('请先选择应用')
        return
      }
      if (this.moveSort === undefined) {
        this.$message.info('请先输入排序')
        return
      }
      const id = this.moveSubject
      if (Array.isArray(this.previewTableData) && this.previewTableData.length) {
        this.previewTableData.forEach(item => {
          // 当前行的应用列表不为空
          if (Array.isArray(item.columns) && item.columns.length) {
            // 查找目标应用所在位置
            const subjectIndex = item.columns.findIndex(column => {
              return column.id === id
            })
            let insertIndex = this.moveSort - 1
            // 1.目标应用存在 2.目标应用所在位置就是目标位置位置，无需移动
            if (subjectIndex !== -1 && subjectIndex !== insertIndex) {
              // 防止数组下标越界
              if (insertIndex > item.columns.length - 1) {
                insertIndex = item.columns.length - 1
              }
              const temp = item.columns.splice(subjectIndex, 1)
              item.columns.splice(insertIndex, 0, temp[0])
            }
          }
        })
      }
    },
    sortStart () {
      this.moving = true
    },
    sortEnd () {
      this.$nextTick(() => {
        this.moving = false
      })
    },
    closeDialog () {
      this.dialogInfo = ''
    },
    submitBefore (data) {
      const rows = []
      if (Array.isArray(this.previewTableData) && this.previewTableData.length) {
        this.previewTableData.forEach(item => {
          const subjects = []
          if (Array.isArray(item.columns) && item.columns.length) {
            item.columns.forEach(column => {
              subjects.push(column.id)
            })
          }
          rows.push({
            communityId: item.data.communityId,
            subjects
          })
        })
      }
      const params = {
        promotionType: data.showType,
        rows
      }
      return params
    }
  }
}
</script>

<style lang="scss" scoped>
.SpreadMgrForm {
  .operation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    .operation-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      .select-communityName {
        ::v-deep .el-input__inner {
          text-overflow: ellipsis;
        }
      }
    }
  }
  .subject-list {
    height: 72px;
    padding-bottom: 15px;
    border-bottom: 1px solid #EBEEF5;
    .columns {
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 15px;
      user-select: none;
      .columns-list {
        display: inline-flex;
        .columns-item {
          margin-right: 10px;
          ::v-deep {
            .tag-text {
              display: inline-block;
              height: 100%;
            }
            .tag-text-operable {
              cursor: grab;
            }
          }
        }
      }
    }
  }
  ::v-deep {
    .table-column-header {
      height: 48px;
      .vxe-cell {
        .vxe-filter-wrapper {
          display: none; // 隐藏表头中的筛选按钮
        }
      }
    }
    .table-column {
      .vxe-cell {
        padding: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .vxe-cell-status {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .dialog-content {
    text-align: left;
    padding: 0 20px;
    margin-bottom: 20px;
    .info-list {
      text-align: center;
      padding: 0 40px;
      max-height: 200px;
      overflow: auto;
      .info-item {
        text-align: left;
        display: inline-block;
      }
    }
  }
}
.list-complete-item {
  transition: all 0.5s;
}
.list-complete-enter, .list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
