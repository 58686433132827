var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SpreadMgrForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "推广标签",
                        rules: [
                          {
                            required: true,
                            message: "请选择推广标签",
                            trigger: "change",
                          },
                        ],
                        prop: "showType",
                      },
                    },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showTypeText,
                            expression: "showTypeText",
                          },
                        ],
                        domProps: { textContent: _vm._s(_vm.showTypeText) },
                      }),
                      _c("v-select", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.showTypeText,
                            expression: "!showTypeText",
                          },
                        ],
                        attrs: { options: _vm.showTypeOps },
                        model: {
                          value: _vm.form.showType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "showType", $$v)
                          },
                          expression: "form.showType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "本次适配项目",
                        rules: [
                          {
                            required: true,
                            message: "请选择本次适配项目",
                            trigger: "change",
                          },
                        ],
                        prop: "communityList",
                      },
                    },
                    [
                      _vm.communityListText
                        ? _c("div", {
                            domProps: {
                              textContent: _vm._s(_vm.communityListText),
                            },
                          })
                        : _c("v-button", {
                            attrs: { text: "点击选择" },
                            on: { click: _vm.selectCommunity },
                          }),
                    ],
                    1
                  ),
                  _vm.showTable
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "应用推荐概况" } },
                            [
                              _c(
                                "div",
                                { staticClass: "operation" },
                                [
                                  _vm.pageType === 0
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: false,
                                              expression: "false",
                                            },
                                          ],
                                          staticClass: "operation-item",
                                        },
                                        [
                                          _vm._v(
                                            "\n                项目名称：\n                "
                                          ),
                                          _c("v-select", {
                                            ref: "communityName",
                                            staticClass: "select-communityName",
                                            attrs: {
                                              "async-options": "",
                                              placeholder: "请输入项目名称",
                                              options: _vm.communityOps,
                                              filterable: "",
                                              clearable: "",
                                              width: 150,
                                            },
                                            on: { change: _vm.communityChange },
                                            model: {
                                              value: _vm.communityId,
                                              callback: function ($$v) {
                                                _vm.communityId = $$v
                                              },
                                              expression: "communityId",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: false,
                                          expression: "false",
                                        },
                                      ],
                                      staticClass: "operation-item",
                                    },
                                    [
                                      _vm._v(
                                        "\n                应用类型：\n                "
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          options: _vm.subjectTypeOps,
                                          width: 150,
                                        },
                                        model: {
                                          value: _vm.subjectType,
                                          callback: function ($$v) {
                                            _vm.subjectType = $$v
                                          },
                                          expression: "subjectType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "operation-item" },
                                    [
                                      _vm._v(
                                        "\n                应用名称：\n                "
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          "async-options": "",
                                          "value-type": "string,number",
                                          placeholder: "请输入应用名称",
                                          options: _vm.subjectOps,
                                          filterable: "",
                                          clearable: "",
                                          width: 150,
                                        },
                                        model: {
                                          value: _vm.subId,
                                          callback: function ($$v) {
                                            _vm.subId = $$v
                                          },
                                          expression: "subId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("v-button", {
                                    attrs: { type: "success", text: "搜索" },
                                    on: { click: _vm.search },
                                  }),
                                ],
                                1
                              ),
                              _c("el-transfe", {
                                attrs: { data: _vm.tableHeaders },
                                model: {
                                  value: _vm.selectedHeaders,
                                  callback: function ($$v) {
                                    _vm.selectedHeaders = $$v
                                  },
                                  expression: "selectedHeaders",
                                },
                              }),
                              _c(
                                "vxe-table",
                                {
                                  ref: "vxeTable",
                                  attrs: {
                                    data: _vm.tableData,
                                    "max-height": "345",
                                    "show-overflow": "",
                                    border: "",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.tableHeaders,
                                    function (item, index) {
                                      return [
                                        _c("vxe-table-column", {
                                          key: index,
                                          attrs: {
                                            field: item.id,
                                            title: item.name,
                                            fixed:
                                              index === 0 ? "left" : undefined,
                                            "header-class-name":
                                              "table-column-header",
                                            "class-name":
                                              index === 0 ? "" : "table-column",
                                            "min-width": "120",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "header",
                                                fn: function (scope) {
                                                  return [
                                                    _c("v-checkbox", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            item.id !=
                                                            "communityName",
                                                          expression:
                                                            "item.id != 'communityName'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        indeterminate:
                                                          item.isIndeterminate,
                                                        disabled: item.disabled,
                                                        label: item.name,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleCheckAllChange(
                                                            scope,
                                                            item
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: item.checked,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "checked",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.checked",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    index === 0
                                                      ? _c("span", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              scope.row[item.id]
                                                            ),
                                                          },
                                                        })
                                                      : _c("span", {
                                                          staticClass:
                                                            "vxe-cell-status",
                                                          style: {
                                                            cursor:
                                                              scope.row[
                                                                item.id
                                                              ] === undefined
                                                                ? "not-allowed"
                                                                : "pointer",
                                                            "background-color":
                                                              _vm.getColor(
                                                                scope.row[
                                                                  item.id
                                                                ]
                                                              ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.cellStatusClick(
                                                                scope,
                                                                item,
                                                                index
                                                              )
                                                            },
                                                          },
                                                        }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "推广预览" } },
                            [
                              _vm.pageType === 0
                                ? _c(
                                    "div",
                                    { staticClass: "operation" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "operation-item" },
                                        [
                                          _vm._v(
                                            "\n                单应用全项目移动：\n                "
                                          ),
                                          _c("v-select", {
                                            attrs: {
                                              placeholder: "请输入应用名称",
                                              options: _vm.moveSubjectOps,
                                              filterable: "",
                                              clearable: "",
                                              width: 150,
                                            },
                                            model: {
                                              value: _vm.moveSubject,
                                              callback: function ($$v) {
                                                _vm.moveSubject = $$v
                                              },
                                              expression: "moveSubject",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "operation-item" },
                                        [
                                          _vm._v(
                                            "\n                排序：\n                "
                                          ),
                                          _c("v-input-number", {
                                            attrs: {
                                              placeholder: "请输入排序",
                                              controls: "",
                                              controlsPosition: "right",
                                              min: 1,
                                              max: 99,
                                            },
                                            model: {
                                              value: _vm.moveSort,
                                              callback: function ($$v) {
                                                _vm.moveSort = $$v
                                              },
                                              expression: "moveSort",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-button", {
                                        attrs: {
                                          type: "success",
                                          text: "确认排序",
                                        },
                                        on: { click: _vm.sort },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._l(
                                _vm.previewTableData,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "subject-list" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(item.data.communityName) + "："
                                        ),
                                      ]),
                                      _c(
                                        "sortable-list",
                                        {
                                          staticClass: "columns",
                                          attrs: {
                                            axis: "x",
                                            lockAxis: "x",
                                            lockOffset: "100%",
                                            lockToContainerEdges: "",
                                            "use-drag-handle": "",
                                          },
                                          on: {
                                            "sort-start": _vm.sortStart,
                                            input: _vm.sortEnd,
                                          },
                                          model: {
                                            value: item.columns,
                                            callback: function ($$v) {
                                              _vm.$set(item, "columns", $$v)
                                            },
                                            expression: "item.columns",
                                          },
                                        },
                                        [
                                          _c(
                                            "transition-group",
                                            {
                                              staticClass: "columns-list",
                                              attrs: { name: "list-complete" },
                                            },
                                            _vm._l(
                                              item.columns,
                                              function (
                                                columnItem,
                                                columnIndex
                                              ) {
                                                return _c("sortable-item", {
                                                  key: columnItem.id,
                                                  staticClass: "columns-item",
                                                  class: _vm.moving
                                                    ? ""
                                                    : "list-complete-item",
                                                  attrs: {
                                                    index: columnIndex,
                                                    item: columnItem,
                                                    headers: _vm.tableHeaders,
                                                    rowIndex: index,
                                                    row: item.data,
                                                    method: _vm.cellStatusClick,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "项目列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getCommunityListURL,
          headers: _vm.communityTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.form.communityList,
          handleData: _vm.handleData,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "communityList", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "communityList", $event)
          },
          callback: _vm.callback,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "项目名称" },
                  model: {
                    value: _vm.searchParams.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityName", $$v)
                    },
                    expression: "searchParams.communityName",
                  },
                }),
                _c("v-select", {
                  attrs: { options: _vm.communityStageOps, label: "项目阶段" },
                  model: {
                    value: _vm.searchParams.communityStage,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStage", $$v)
                    },
                    expression: "searchParams.communityStage",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司", placeholder: "查询所属公司" },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.tenantParams,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "500px",
            title: "应用推广配置错误",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.closeDialog,
          },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("p", [_vm._v("您所配置的")]),
            _c("div", { staticClass: "info-list" }, [
              _c("span", { staticClass: "info-item" }, [
                _vm._v(_vm._s(_vm.dialogInfo)),
              ]),
            ]),
            _c("p", [_vm._v("应用已超出推荐列表上限，无法再添加")]),
            _c("p", [_vm._v("请知悉")]),
          ]),
          _c("v-button", {
            attrs: { text: "知道了" },
            on: {
              click: function ($event) {
                _vm.dialogVisible = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }