import { mapHelper } from 'common/utils'

// 推广标签
const showType = [
  {
    text: '首页展示',
    value: 0
  },
  {
    text: '生活展示',
    value: 3
  },
  {
    text: '热门应用',
    value: 1
  },
  {
    text: '全新应用',
    value: 2
  }
]

const {
  map: showTypeMap,
  setOps: setShowTypeOps
} = mapHelper.setMap(showType)

// 项目状态
const communityStatus = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

const {
  map: communityStatusMap,
  setOps: setCommunityStatusOps
} = mapHelper.setMap(communityStatus)

// 应用类型
const subjectType = [
  {
    text: '平台级应用',
    value: 1
  },
  {
    text: '公司级应用',
    value: 2
  },
  {
    text: '项目级应用',
    value: 3
  }
]

const {
  map: subjectTypeMap,
  setOps: setSubjectTypeOps
} = mapHelper.setMap(subjectType)

export {
  showTypeMap,
  setShowTypeOps,
  communityStatusMap,
  setCommunityStatusOps,
  subjectTypeMap,
  setSubjectTypeOps
}
