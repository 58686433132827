// 查询列表
const getListURL = `${API_CONFIG.controlBaseURL}subject/promotion/listSubjectPromotions`
// 导出列表
const exportListURL = `${API_CONFIG.controlBaseURL}subject/promotion/export`
// 查询应用列表 包含应用select2
const getSubjectListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectSelect2`
// 查询操作人列表 最后操作人select2
const getUserListURL = `${API_CONFIG.butlerBaseURL}agreement/user/search/keyword`

// 查询项目阶段选项
const getCommunityStageOpsURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 查询项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 应用推荐概况
const getSubjectPromotionMatrixURL = `${API_CONFIG.controlBaseURL}subject/promotion/getSubjectPromotionMatrix`
// 保存
const saveSubjectPromotionMatrixURL = `${API_CONFIG.controlBaseURL}subject/promotion/saveSubjectPromotionMatrix`

export {
  getListURL,
  exportListURL,
  getSubjectListURL,
  getUserListURL,
  getCommunityStageOpsURL,
  getCommunityListURL,
  getSubjectPromotionMatrixURL,
  saveSubjectPromotionMatrixURL
}
